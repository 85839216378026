<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Employees</H2>
      </div>
      <MainButton
        :clickFunction="openAddEmployee"
        title="Add employee"
        styleClass="primary"
      />
    </div>
    <SearchInput placeholder="Search" v-model="searchString" />
    <LoadingRowTable v-if="!employees" :color="'#444'" />
    <table v-else class="standardTable">
      <br />
      <tr class="standardTableHeader">
        <th>Username</th>
        <th>Firstname</th>
        <th>Lastname</th>
        <th>Email</th>
        <th>Social Security number</th>
      </tr>
      <EmployeeRowItem
        :key="employee.id"
        :employee="employee"
        v-for="employee in filteredEmployees"
      />
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import AddEmployeePopupBoxVue from '@/components/management/AddEmployeePopupBox'
import EmployeeRowItem from '@/components/management/EmployeeRowItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import SearchInput from '@/layout/SearchInput'
import MainButton from '@/layout/MainButton'
import Search from '@/components/Search'
import H2 from '@/layout/typography/H2'

export default {
  components: { EmployeeRowItem, LoadingRowTable, MainButton, Search, SearchInput, H2 },
  data() {
    return {
      employees: undefined,
      searchString: ''
    }
  },
  computed: {
    filteredEmployees() {
      if (!this.searchString) {
        return this.employees
      }
      return this.employees.filter((employee) => {
        if (employee.username.toLowerCase().includes(this.searchString.toLowerCase())) {
          return employee
        }
      })
    },
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  created() {
    this.getEmployees()
  },
  methods: {
    openAddEmployee() {
      this.$store.commit('SET_POPUP', { component: AddEmployeePopupBoxVue, properties: { getEmployees: this.getEmployees } })
    },
    getEmployees() {
      axios.get('employees/getEmployees', {}).then((response) => {
        this.employees = response.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>