<template>
  <tr @click="openEmployee(employee.id)" style="cursor:pointer;" class="tableRow">
    <td>{{ employee.username }}</td>
    <td>{{ employee.firstname }}</td>
    <td>{{ employee.lastname }}</td>
    <td>{{ employee.email }}</td>
    <td>{{ employee.ssn }}</td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    employee: Object,
  },
  methods: {
    openEmployee(id) {
      this.$router.push({ name: 'Employee', params: { employeeId: id } })
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
}
</script>

<style scoped lang="scss">


</style>